<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">
          {{ new Date().getFullYear() }} &nbsp;&copy;&nbsp;
        </span>
        <a
          href="https://www.segeplan.gob.gt"
          target="_blank"
          class="text-dark-75 text-hover-primary"
        >
          Secretar&iacute;a de Planificaci&oacute;n y Programaci&oacute;n de la
          Presidencia
        </a>
      </div>
      <div class="nav nav-dark">
        <template>
          <div class="text-center">
            <v-dialog
              v-model="dialogCondiciones"
              width="800"
              transition="scroll-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn text class="nav-link pr-3 pl-3" v-bind="attrs" v-on="on">
                  Condiciones de uso
                </v-btn>
              </template>

              <v-card
                elevation="0"
                class="card card-custom  gutter-b mb-0"
                style="background-size: 100%; background-image: url('/media/svg/shapes/abstract-4.svg');"
              >
                <v-card-title class="text-h5">
                  Condiciones para la utilización del SICOOPERA
                </v-card-title>

                <v-card-text class="text-h6 text-justify text-dark-50 mt-4">
                  Los usuarios de SICOOPERA, aceptan por el sólo hecho de su
                  registro en el sitio, que los datos e información a registrar
                  dentro de los diferentes submódulos SICOOPERA es confiable y
                  fehaciente, y que la asignación de usuario, otorga su
                  conformidad para que la información en los diferentes
                  submódulos, formen parte de las estadísticas y reportes que se
                  procesen y publiquen por parte de la Secretaría de
                  Planificación y Programación de la Presidencia, sin que la
                  misma tenga responsabilidad equívoca disponible públicamente
                  para efectos. Los usuarios no podrán realizar actos maliciosos
                  o atentatorios contra el sitio, que de alguna manera pueda
                  dañar, inutilizar, sobrecargar, deteriorar, impedir o limitar
                  la utilización de todas o algunas de las funcionalidades del
                  mismo.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogCondiciones = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>

        <!-- <a
          href="http://keenthemes.com/metronic"
          target="_blank"
          class="nav-link pr-3 pl-0"
        >
          Condiciones de uso
        </a>
        <a
          href="http://keenthemes.com/metronic"
          target="_blank"
          class="nav-link px-3"
        >
          Team
        </a>
        <a
          href="http://keenthemes.com/metronic"
          target="_blank"
          class="nav-link pl-3 pr-0"
        >
          Contact
        </a> -->
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  data() {
    return {
      dialogCondiciones: false,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
};
</script>
